import { IconAsset } from "../assets/IconAsset"

export function HorizontalSpacing({ style }) {
    return (
        <div style={{ width: '10px', ...style }}></div>
    )
}
export function VerticalSpacing({ style }) {
    return (
        <div style={{ height: '10px', ...style }}></div>
    )
}
export function IconImage({ src, style, onClick }) {
    return (
        <img
            src={src}
            style={{
                margin: '15px',
                width: '20px',
                userSelect: 'none',
                cursor: onClick ? 'pointer' : 'default',
                ...style
            }}
            onClick={onClick}
        />
    )
}
export function IconRoundLargeImage({ src, style, imageStyle, onClick }) {
    return (
        <div 
            style={{
                display: 'flex',
                width: '70px',
                height: '70px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '70px',
                padding: '10px',
                ...style
            }}>
            <img
                src={src}
                style={{
                    width: '60px',
                    userSelect: 'none',
                    cursor: onClick ? 'pointer' : 'default',
                    ...imageStyle
                }}
                onClick={onClick}
            />
        </div>
    )
}

export function LoadingComponent() {
    return (
        <div>
            <img src={IconAsset.Loading} style={{
                width: '38px',
                height: '38px',
                userSelect: 'none',
                margin: '0 10px'
            }} />
        </div>
    )
}