import { useEffect, useState } from "react";
import { FlexColumnContainer } from "../../components/Container";
import { IconAsset } from "../../assets/IconAsset";
import { AuthRequestModel } from "../../services/database/AuthRequest.Model";
import { useSearchParams } from "react-router-dom";
import { LoadingComponent, VerticalSpacing } from "../../components/Miscs";
import { TyphographyHeader2, TyphographyHeader3, TypographyError, TypographyGray } from "../../components/Typography";
import { InputPrimary } from "../../components/Inputs";
import { ButtonDefault, ButtonDisabled } from "../../components/Buttons";

export function ForgotPin() {
    const [ isLoading, setLoading ] = useState(true)
    const [ authRequest, setAuthRequest ] = useState()
    const [ token, setToken ] = useState()
    const [ tokenState, setTokenState ] = useState()
    const [ success, setSuccess ] = useState()
    const [ error, setError ] = useState()
    const [ inputs, setInputs ] = useState({
        newPin: '',
        confirmNewPin: ''
    })
    const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    async function Init() {
        const tokenParams = searchParams.get('token')
        setToken(tokenParams)
        try {
            const authRequest = await AuthRequestModel.verifyAuthRequestValidityByToken(tokenParams)
            setAuthRequest(authRequest)
        } catch (error) {
            if (error.name === 'JsonWebTokenError') {
                setTokenState('An error occured, session might be expired.')
            }
            
            if (error.code == 'ERR_NETWORK') {
                setTokenState('There is a network connection problem while fetching the data.')
            }
            setTokenState(error.message)

            setLoading(false)

        }
        setLoading(false)
    }
    // On Form submit
    async function onSubmit() {
        setLoading(true)
        if (inputs.newPin !== inputs.confirmNewPin) {
            return setError('Pin not match')
        }
        try {
            await AuthRequestModel.validateAuthRequestForgotPin(token, inputs.newPin)
        } catch(error) {
            return setError('Session already expired, please try sending a request again')
        }
        setLoading(false)
        setSuccess(true)
    }
    const SuccessComponent = () => {
        return (
            <>
                <VerticalSpacing style={{height: '100px'}} />
                <TyphographyHeader3>Congratulations! You've successfully changed your pin</TyphographyHeader3>
            </>
        )
    }
    useEffect(() => {
        Init()
    }, [])
    useEffect(() => {
        if (inputs.newPin.length != 0 && inputs.confirmNewPin.length != 0) {
            setSubmitButtonEnabled(true)
        } else {
            setSubmitButtonEnabled(false)
        }
    }, [inputs])
    return (
        <FlexColumnContainer style={{ alignItems: 'center', marginTop: '80px' }}>
            <img src={IconAsset.BILISLogo} style={{ width: '300px' }} />
            <FlexColumnContainer style={{ alignContent: 'center' }}>
                <VerticalSpacing style={{ height: '60px' }} />
                {
                    !isLoading
                        ?   !success
                            ?   !tokenState
                                ? <>
                                    <FlexColumnContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <TyphographyHeader2>Reset your Pin</TyphographyHeader2>
                                        <TypographyGray>No worries, it happens to us sometimes.</TypographyGray>
                                    </FlexColumnContainer>
                                    <VerticalSpacing style={{ height: '40px' }} />
                                    <FlexColumnContainer style={{ maxWidth: '300px' }}>
                                        {
                                            error &&
                                            <TypographyError>
                                                {error}
                                            </TypographyError>

                                        }
                                    </FlexColumnContainer>
                                    <FlexColumnContainer style={{ alignContent: 'center', justifyContent: 'center' }}>
                                        <VerticalSpacing />
                                        <InputPrimary maxLength='4' type={'password'} placeholder={'New Pin'} onChange={(evt) => setInputs({ ...inputs, newPin: evt.target.value })}></InputPrimary>
                                        <VerticalSpacing />
                                        <InputPrimary maxLength='4' type={'password'} placeholder={'Confirm new pin'} onChange={(evt) => setInputs({ ...inputs, confirmNewPin: evt.target.value })}></InputPrimary>
                                        <VerticalSpacing />
                                        {
                                            isSubmitButtonEnabled
                                                ? <ButtonDefault value={'Submit'} onClick={onSubmit}></ButtonDefault>
                                                : <ButtonDisabled></ButtonDisabled>

                                        }
                                        <VerticalSpacing />
                                    </FlexColumnContainer>
                                </>
                                :
                                <>
                                    <VerticalSpacing style={{ marginTop: '80px' }} />
                                    <TypographyError>
                                        {tokenState}
                                    </TypographyError>
                                </>
                            : <SuccessComponent />

                        :
                        <>
                            <VerticalSpacing style={{ marginTop: '80px' }} />
                            <LoadingComponent />
                        </>
                }
                
            </FlexColumnContainer>
        </FlexColumnContainer>
    )
}