import { BILIS_colors } from "../values/String"
import { motion } from "framer-motion"


export function ButtonDefault({ style, value, onClick }) {
    return (
        <input 
            type='submit' 
            value={value}
            style={{
                border: 'none',
                borderRadius: '5px',
                padding: '10px',
                color: 'white',
                fontWeight: 'bold',
                cursor: 'pointer',
                background: BILIS_colors.primary,
                textAlign: 'center',
                ...style
            }} 
            onClick={onClick}
        ></input>
    )
}

export function ButtonDisabled({ style, value, onClick }) {
    return (
        <input 
            type='submit' 
            value={value}
            style={{
                border: 'none',
                borderRadius: '5px',
                padding: '10px',
                color: 'white',
                fontWeight: 'bold',
                background: 'lightgray',
                textAlign: 'center'
            }}
        ></input>
    )
}

export function ButtonWhite({ style, type, value, onClick }) {
    return (
        <motion.button 
            type='submit' 
            style={{
                border: '1px solid',
                borderColor: BILIS_colors.primary,
                borderRadius: '5px',
                padding: '10px',
                color: BILIS_colors.primary,
                fontWeight: 'bold',
                cursor: 'pointer',
                background: '#FFF',
                textAlign: 'center',
                ...style
            }}
            onClick={onClick}
            whileHover={{
                backgroundColor: BILIS_colors.primary,
                color: '#FFF'
            }}
            
        >
            {value}
        </motion.button>
    )
}