
export function InputPrimary(props, {style, type, placeholder, value, onChange }) {
    return (
        <input 
            
            type={type}
            style={{
                padding: '10px',
                border: '1px solid gray',
                borderRadius: '5px',
                outline: 'none',
                backgroundColor: 'whitesmoke',
                ...style
            }} 
            placeholder={placeholder} 
            value={value} 
            onChange={onChange}
            {...props}
        >
          
        </input>
    )
}

