export function FlexColumnContainer({ style, children, onClick }) {
    return(
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                ...style
            }}
            onClick={onClick}>
            {children}
        </div>
    )
}


export function FlexRowContainer({ style, children, onClick }) {
    return(
        <div 
            style={{
            display: 'flex',
            flexDirection: 'row',
            ...style
            }}
            onClick={onClick}
        >
            {children}
        </div>
    )
}


