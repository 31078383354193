import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { IconAsset } from "../../assets/IconAsset"
import { FlexColumnContainer } from "../../components/Container"
import { InviteRequestModel } from "../../services/database/InviteAdminRequest.Model";
import { TypographyDefault, TypographyError } from "../../components/Typography";
import { IconImage, LoadingComponent, VerticalSpacing } from "../../components/Miscs";
import { BILIS_Domains } from "../../values/String";
import { ImageAsset } from "../../assets/ImageAsset";
export function InviteAdminRequest() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(true)
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    const [inviteRequest, setInviteRequest] = useState()
    // Initialize
    async function Init() {
        const responseParams = searchParams.get('response')
        const tokenParams = searchParams.get('token')
        // The function try to get the invite request from the database and set the invite 
        // request if it's a success or set an error if it isn't.
        try {
            const _inviteRequest = await InviteRequestModel.getInviteRequest(tokenParams)
            setInviteRequest(_inviteRequest)
        } catch (error) {
            if (error.name === 'JsonWebTokenError') {
                setError('An error occured, session might be expired.')
            }
            
            if (error.code == 'ERR_NETWORK') {
                setError('There is a network connection problem while fetching the data.')
            }
            setError(error.message)

            setLoading(false)
        }
        setResponse(responseParams)
        // This code checks if the user has approved the invite request and it proceeds to approve the invite request.
        if (responseParams === 'approve') {
            await InviteRequestModel.approveInviteRequest(tokenParams)
            setLoading(false)
            // Redirect user to admin panel
            setTimeout(() => {
                window.location.href = BILIS_Domains.ADMIN_PANEL
            }, 3000)
        }
        // If the responseParams is equal to decline, then we decline the invite request.
        if (responseParams === 'decline') {
            await InviteRequestModel.declineInviteRequest(tokenParams)
            setLoading(false)
        }
    }
    useEffect(() => {
        Init()
    }, [])
    return (
        <FlexColumnContainer style={{ alignItems: 'center', marginTop: '80px' }}>
            <img src={IconAsset.BILISLogo} style={{ width: '300px' }} />
            <FlexColumnContainer style={{ alignContent: 'center' }}>
                <VerticalSpacing style={{ height: '60px' }} />
                {
                    !isLoading
                        ? 
                        <>
                            {
                                response === 'approve' && !error &&
                                <>
                                    <IconImage src={ImageAsset.WellDone} style={{ width: '150px' }} />
                                    <VerticalSpacing />
                                    <TypographyDefault>Congratulations! You are now an admin!</TypographyDefault>
                                    <VerticalSpacing />
                                    <TypographyDefault>Redirecting you now to the <b>Admin Panel</b></TypographyDefault>
                                    <VerticalSpacing style={{ marginTop: '30px' }} />
                                    <FlexColumnContainer style={{alignItems: 'center'}}>
                                        <LoadingComponent />
                                    </FlexColumnContainer>
                                </>
                            }
                            {
                                response === 'decline' && !error &&
                                <>
                                    <IconImage src={ImageAsset.Cancel} style={{ width: '150px' }} />
                                    <VerticalSpacing />
                                    <TypographyDefault>You've declined the invitation request</TypographyDefault>
                                </>

                            }
                            {
                                response !== 'approve' && response !== 'decline' && !error &&
                                <TypographyError>The request is malformed.</TypographyError>

                            }
                            {
                                error &&
                                <>
                                    <VerticalSpacing style={{ marginTop: '80px' }} />
                                    <TypographyError>{error}</TypographyError>
                                </>
                            }
                        </>
                        : 
                        <>
                            <VerticalSpacing style={{ marginTop: '80px' }} />
                            <FlexColumnContainer style={{alignItems: 'center'}}>
                                <LoadingComponent />
                            </FlexColumnContainer>
                        </>
                }
            </FlexColumnContainer>
        </FlexColumnContainer>
    )
}