import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { IconAsset } from "../../assets/IconAsset"
import { ButtonDefault, ButtonDisabled } from "../../components/Buttons"
import { FlexColumnContainer } from "../../components/Container"
import { InputPrimary } from "../../components/Inputs"
import { LoadingComponent, VerticalSpacing } from "../../components/Miscs"
import { TyphographyHeader2, TyphographyHeader3, TypographyDefault, TypographyError, TypographyGray } from "../../components/Typography"
import { AuthRequestModel } from "../../services/database/AuthRequest.Model"

export function ForgotPassword() {
    const [inputs, setInputs] = useState({
        newPassword: '',
        confirmNewPassword: ''
    })
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false)
    const [tokenState, setTokenState] = useState(null)
    const [token, setToken] = useState(null)
    const [authRequest, setAuthRequest] = useState({})
    const [success, setSuccess] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    // Initialize content
    async function Init() {
        console.log('load')
        const tokenParams = searchParams.get('token')
        setToken(tokenParams)
        try {
            const authRequest = await AuthRequestModel.verifyAuthRequestValidityByToken(tokenParams)
            setAuthRequest(authRequest)
        } catch (error) {
            
            if (error.name === 'JsonWebTokenError') {
                setTokenState('An error occured, session might be expired.')
            }

            if (error.code == 'ERR_NETWORK') {
                setTokenState('There is a network connection problem while fetching the data.')
            }
            setTokenState(error.message)

            setLoading(false)
        }
        setLoading(false)
    }
    // On Form submit
    async function onSubmit() {
        setLoading(true)
        const isPasswordWrong = validatePassword(inputs.newPassword)
        if (isPasswordWrong) return setError(isPasswordWrong)
        if (inputs.newPassword !== inputs.confirmNewPassword) {
            return setError('Password not match')
        }
        try {
            await AuthRequestModel.validateAuthRequestForgotPassword(token,inputs.newPassword)
        } catch(error) {
            return setError('Session already expired, please try sending a request again')
        }
        setLoading(false)
        setSuccess(true)
    }
    // Validate if password format is correct
    function validatePassword(password) {
        const BILIS_Password_Condition_List = [
            {
                condition: 'contains at least 8 characters',
                regex: "^.{8,}$",
            },
            {
                condition: 'contains at least one number (0-9)',
                regex: "[0-9]",
            },
            {
                condition: 'contains both one lower (a-z) and upper case letters (A-Z)',
                regex: "(?=.?[A-Z])(?=.?[a-z])",
            },
        ];
        for (var i = 0; i < BILIS_Password_Condition_List.length; i++) {
            var validator = new RegExp(BILIS_Password_Condition_List[i].regex);
            var isConditionMet = validator.test(password);
            if (!isConditionMet) {
                return BILIS_Password_Condition_List[i].condition
            }
        }
        return undefined
    }
    useEffect(() => {
        Init()
    }, [])

    useEffect(() => {
        if (inputs.newPassword.length != 0 && inputs.confirmNewPassword.length != 0) {
            setSubmitButtonEnabled(true)
        } else {
            setSubmitButtonEnabled(false)
        }
    }, [inputs])
    const SuccessComponent = () => {
        return (
            <>
                <VerticalSpacing style={{height: '100px'}} />
                <TyphographyHeader3>Congratulations! You've successfully changed your password</TyphographyHeader3>
            </>
        )
    }
    return (
        <FlexColumnContainer style={{ alignItems: 'center', marginTop: '80px' }}>
            <img src={IconAsset.BILISLogo} style={{ width: '300px' }} />
            <FlexColumnContainer style={{ alignContent: 'center' }}>
                <VerticalSpacing style={{ height: '60px' }} />
                {
                    !isLoading
                        ?   !success
                            ?   !tokenState
                                ? <>
                                    <FlexColumnContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <TyphographyHeader2>Reset your Password</TyphographyHeader2>
                                        <TypographyGray>No worries, it happens to us sometimes.</TypographyGray>
                                    </FlexColumnContainer>
                                    <VerticalSpacing style={{ height: '40px' }} />
                                    <FlexColumnContainer style={{ maxWidth: '300px' }}>
                                        {
                                            error &&
                                            <TypographyError>
                                                {error}
                                            </TypographyError>

                                        }
                                    </FlexColumnContainer>
                                    <FlexColumnContainer style={{ alignContent: 'center', justifyContent: 'center' }}>
                                        <VerticalSpacing />
                                        <InputPrimary type={'password'} placeholder={'New password'} onChange={(evt) => setInputs({ ...inputs, newPassword: evt.target.value })}></InputPrimary>
                                        <VerticalSpacing />
                                        <InputPrimary type={'password'} placeholder={'Confirm new password'} onChange={(evt) => setInputs({ ...inputs, confirmNewPassword: evt.target.value })}></InputPrimary>
                                        <VerticalSpacing />
                                        {
                                            isSubmitButtonEnabled
                                                ? <ButtonDefault value={'Submit'} onClick={onSubmit}></ButtonDefault>
                                                : <ButtonDisabled></ButtonDisabled>

                                        }
                                        <VerticalSpacing />
                                    </FlexColumnContainer>
                                </>
                                :
                                <>
                                    <VerticalSpacing style={{ marginTop: '80px' }} />
                                    <TypographyError>
                                        {tokenState}
                                    </TypographyError>
                                </>
                            : <SuccessComponent />

                        :
                        <>
                            <VerticalSpacing style={{ marginTop: '80px' }} />
                            <LoadingComponent />
                        </>
                }
                
            </FlexColumnContainer>
        </FlexColumnContainer>
    )
}
