import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import './App.css';
import { ForgotPassword } from './pages/auth_requests/ForgotPassword';
import { ForgotPin } from './pages/auth_requests/ForgotPin';
import { InviteAdminRequest } from './pages/auth_requests/InviteAdminRequest';
import { FrontPage } from './pages/FrontPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<FrontPage />} />
      <Route path="/auth-request/invite-admin" element={<InviteAdminRequest />} />
      <Route path="/auth-request/forgot-password" element={<ForgotPassword />} />
      <Route path="/auth-request/forgot-pin" element={<ForgotPin />} />
    </>
  )
);





