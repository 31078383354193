import axios from "axios"
import { Server } from "../../values/Server"

class AuthRequest {
   /**
    * @description Check if authenticated token is still valid
    * @param {*} token 
    * @returns 
    */
   verifyAuthRequestValidityByToken(token) {
      return new Promise((resolve, reject) => {
         axios({
            method: 'GET',
            url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/user/auth-request/${token}`
         }).then(snapshot => {
            resolve(snapshot.data)
        }).catch(error => {
            if (error?.response?.data?.error) {
               reject(error.response.data.error)
            }
            reject(error)
        })
      })
   }
   /**
    * @description Apply the token for changing password
    */
   validateAuthRequestForgotPassword(token, newPassword) {
      return new Promise((resolve, reject) => {
         axios({
            method: 'POST',
            url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/user/auth-request/forgot-password/${token}`,
            data: {
               data: {
                  password: newPassword
               }
            }
         }).then(snapshot => {
            resolve(snapshot.data)
        }).catch(error => {
            reject(error.response.data.error)
        })
      })
   }
   /**
    * @description Apply the token for changing pin
    * @param {*} token 
    * @param {*} newPin 
    * @returns 
    */
   validateAuthRequestForgotPin(token, newPin) {
      return new Promise((resolve, reject) => {
         axios({
            method: 'POST',
            url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/user/auth-request/forgot-pin/${token}`,
            data: {
               data: {
                  pin: newPin
               }
            }
         }).then(snapshot => {
            resolve(snapshot.data)
        }).catch(error => {
            reject(error.response.data.error)
        })
      })
   }
}

export const AuthRequestModel = new AuthRequest()