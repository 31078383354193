import axios from "axios"
import { Server } from "../../values/Server"

class InviteRequest {
    // Fetch an Invite request using a token
    async getInviteRequest(token) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/invite-request/invite-admin/barangay/${token}`
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data.error)
            })
        })
    }
    // Fetch an Invite request using a token
    async approveInviteRequest(token) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/invite-request/invite-admin/barangay/${token}/approve`
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                throw new Error(error.response.data.error)
            })
        })
    }
    // Fetch an Invite request using a token
    async declineInviteRequest(token) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ORIGIN}${Server.API_ENDPOINT}/invite-request/invite-admin/barangay/${token}/decline`
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data.error)
            })
        })
    }
}

export const InviteRequestModel = new InviteRequest()