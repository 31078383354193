import { IconAsset } from "../assets/IconAsset"
import { FlexColumnContainer } from "../components/Container"
import { TypographyDefault } from "../components/Typography"

const pkg = require('../../package.json')
export function FrontPage() {
    return (
        <FlexColumnContainer style={{alignItems: 'center', marginTop: '200px'}}>
            <img src={IconAsset.BILISLogo} style={{width: '300px'}}/>
            <p>{pkg.version}</p>
        </FlexColumnContainer>
    )
}

const styles = {

}